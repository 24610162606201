.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 76px;
  padding: 0 64px 0 38px;
  background-color: $white;

  &-user {
    display: flex;
    align-items: center;
    text-decoration: none;

    &__avatar {
      width: 45px;
      height: 45px;
      border-radius: 50%;
    }

    .text-black-normal-f16-l28 {
      margin-left: 16px;
    }
  }

  .header-right-side {
    display: flex;
    align-items: center;
  }

  .header-left-side {
    .ant-select-selector {
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .field__select {
      &.ant-select {
        width: 300px;

        .ant-select-selection-item {
          display: block;
        }
      }
    }
  }

  .header__logout {
    margin-left: 16px;
  }
}

.sidebar.hidden {
  & + .page__container {
    .header {
      padding-left: 64px;
    }
  }
}
