.ant-row {
	flex-direction: column;
}

.request-page-list {
	z-index: 2;
	margin-bottom: calc(36px + 38px);
	
	.request-list {
		flex-direction: column;
	}
}

.request-page-item {
	flex-direction: column;
	z-index: 2;
	margin-bottom: 52px;
	
	.request-history-item-content {
		display: flex;
		margin-top: 40px;
	}
	
	.box {
		width: 100%;
	}
}

.request-item-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	
	.request-item-header-title {
		display: flex;
		flex-direction: column;
	}
	
	.btn {
		border-radius: 4px;
	}
}

.request-item-header-btns {
	display: flex;
	gap: 8px;
}

.request-attachment-img {
	width: 100%;
	object-fit: contain;
}

.request-attachment-file {
	display: flex;
	align-items: center;
	gap: 8px;
	width: auto;
	
	&:hover {
		cursor: pointer;
		transition: color 0.3s ease-in;
		
		svg, a {
			transition: none;
			color: $turquoise;
		}
	}
	
	svg {
		height: 16px;
	}
	
	a {
		color: #04040B;
		order: -1;
	}
	
	
}

.request-info {
	width: 40%;
	position: sticky;
	top: 12px;
	display: flex;
	align-self: flex-start;
	flex-direction: column;
	
	.request-info-header {
		display: flex;
		flex-direction: column;
		
		.request-info-header-status {
			margin-top: 8px;
			font-size: 13px;
			line-height: 17px;
			
			&.red {
				color: $red;
			}
		}
	}
	
	.request-info-list {
		margin-top: 24px;
		
		.request-info-item {
			margin-top: 16px;
			display: flex;
			flex-direction: column;
		}
	}
	
	.request-leave-comment {
		.text-black-medium-f22-l30 {
			margin-bottom: 24px;
		}
		
		.btn {
			width: 100%;
		}
	}
}

.request-history {
	width: 60%;
}

.request-history-list {
	display: flex;
	flex-direction: column;
	border-left: 1px solid $greyLight;
	margin-top: 24px;
	margin-left: 7px;
	
	.request-history-item {
		padding-bottom: 32px;
		position: relative;
		
		.request-history-item-header {
			display: flex;
			flex-direction: column;
			padding-left: 32px;
			position: relative;
			
			&:before {
				content: '';
				width: 14px;
				height: 14px;
				background: $white;
				border: 2px solid $pink;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: -7px;
				border-radius: 50%;
			}
			
			&.admin {
				&:before {
					border-color: $turquoise;
				}
			}
		}
		
		.request-history-item-message {
			padding-left: 32px;
		}
		
		.request-history-item-status {
			margin-left: 40px;
			font-size: 16px;
			line-height: 28px;
			
			&.red {
				color: $red;
			}
		}
	}
}

.create-request {
	.ant-form {
		display: flex;
	}
	
	.create-request__form {
		margin-top: 24px;
	}
	
	.create-request__container {
		max-width: 1052px;
	}
	
	.create-request__col {
		width: 430px;
		
		+ .create-request__col {
			margin-left: 24px;
		}
	}
	
	> div {
		& + div {
			margin-top: 8px;
		}
	}
}

.loading-zone-img {
	width: 100%;
	margin-bottom: 20px;
	cursor: pointer;
}

.ant-modal.modal-type-green-close {
	width: max-content !important;
	
	.ant-modal-body {
		padding: 51px 40px 36px 40px;
	}
	
	.loading-zone-img-modal {
		width: 100%;
		height: 500px;
		object-fit: contain;
	}
	
	.ant-modal-close-x {
		width: 37px;
		height: 37px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 10px;
		
		.icon {
			width: 100%;
			height: 100%;
		}
	}
	
	.modal-type-green-close__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 36px;
	}
}

.ant-modal.attachment-file-modal {
	top: 24px;
	
	.request-attachment-img {
		width: 100%;
		height: calc(100vh - 136px);
		object-fit: contain;
	}
}


.request-ediatable {
	.request-ediatable-form {
		display: flex;
		flex-direction: column;
	}
	
	.request-ediatable-id {
		display: flex;
		align-items: center;
		gap: 8px;
		margin-bottom: 24px;
		height: 46px;
	}
	
	.request-ediatable-id-label {
		font-size: 14px;
		line-height: 18px;
		color: #8A92A6;
		cursor: default;
	}
	
	.request-ediatable-id-value {
		font-size: 16px;
		line-height: 28px;
		color: #8A92A6;
		cursor: pointer;
	}
}