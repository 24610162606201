.text-black-bold-f40-l48 {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: $purpleDark;
}
.text-black-bold-f19-l33 {
  font-weight: 600;
  font-size: 19px;
  line-height: 33px;
  color: $purpleDark;
}

.text-white-bold-f40-l48 {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: $white;
}

.text-black-normal-f22-l30 {
  font-size: 22px;
  line-height: 30px;
  color: $purpleDark;
}

.text-blue-medium-f22-l30 {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  color: $blueDark;
}

.text-black-medium-f22-l30 {
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: $purpleDark;
}

.text-white-medium-f22-l30 {
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: $white;
}

.text-purple-dark-bold-f24-l34 {
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  color: $blueDark;
}

.text-black-normal-f16-l28 {
  font-size: 16px;
  line-height: 28px;
  color: $purpleDark;
}

.text-grey-dark-bold-f16-l28 {
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  color: $greyDark;
}

.text-black-medium-f18-l28 {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: $purpleDark;
}

.text-grey-normal-f16-l28 {
  font-size: 16px;
  line-height: 28px;
  color: $grey;
}

.text-grey-dark-normal-f16-l28 {
  font-size: 16px;
  line-height: 28px;
  color: $greyDark;
}

.text-red-normal-f16-l28 {
  font-size: 16px;
  line-height: 28px;
  color: $red;
}

.text-green-normal-f16-l28 {
  font-size: 16px;
  line-height: 28px;
  color: $green;
}

.text-yellow-normal-f16-l28 {
  font-size: 16px;
  line-height: 28px;
  color: $yellow;
}

.text-turquoise-normal-f16-l28 {
  font-size: 16px;
  line-height: 28px;
  color: $turquoise;
}

.text-pink-normal-f16-l28 {
  font-size: 16px;
  line-height: 28px;
  color: $pink;
}

.text-purple-dark-normal-f16-l20 {
  font-size: 16px;
  line-height: 20px;
  color: $purpleDark;
}

.text-purple-dark-medium-f16-l20 {
  font-size: 16px;
  line-height: 20px;
  color: $purpleDark;
  font-weight: 600;
}

.text-pink-medium-f16-l20 {
  font-size: 16px;
  line-height: 20px;
  color: $pink;
  font-weight: 600;
}

.text-grey-dark-normal-f16-l20 {
  font-size: 16px;
  line-height: 20px;
  color: $grey;
}

.text-pink-normal-f16-l20 {
  font-size: 16px;
  line-height: 20px;
  color: $pink;
}

.text-grey-normal-f12-l16 {
  font-size: 12px;
  line-height: 16px;
  color: $greyDark;
}

.text-black-normal-f12-l16 {
  font-size: 12px;
  line-height: 14px;
  color: $purpleDark;
}
