.dashboard {
  .boxes__col {
    &.left {
      min-width: 532px;
      max-width: 532px;
    }

    &.right {
      flex-grow: 1;
    }
  }

  .box.loading {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 590px;
  }

  .dashboard-table-request {
    .label-icon {
      margin-right: 16px;
    }
  }

  .default-table {
    margin-top: 24px;
  }

  .default-table.request-table .ant-table-tbody .ant-table-row {
    height: 88px;
  }

  .dashboard-support__inner {
    display: flex;
    align-items: center;
    margin-top: 16px;
  }

  .dashboard-support__text {
    margin-top: 8px;
    max-width: 392px;
  }

  .dashboard-support__number {
    margin-left: 16px;
  }

  .dashboard-table-request__text {
    max-width: 175px;
  }

  .dashboard-request__description {
    margin-top: 24px;

    span {
      margin-right: 4px;
    }
  }

  .dashboard-request__title {
    padding-bottom: 24px;
    border-bottom: 1px solid $greyLight;
  }

  .dashboard-request-item {
    display: flex;
    align-items: center;
    padding-top: 24px;

    .btn {
      margin-left: auto;
    }

    .icon {
      margin-right: 8px;
      color: $purpleDark;
    }
  }

  .dashboard-request-item__text {
    margin-right: 24px;
  }

  .dashboard-balance {
    display: flex;
    align-items: center;
    padding-right: 38px;

    .dashboard-balance__currency {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 64px;
      height: 64px;
      background: $purpleDark;
      border-radius: 6px;

      .currency-balance-border {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border: 1px solid $turquoise;
        border-radius: 50%;
      }

      .currency-balance-icon {
        color: $turquoise;
        width: 10px;
        height: 17px;
      }
    }

    .dashboard-balance__info {
      margin-left: 40px;
    }

    .dashboard-balance__info-label {
      font-weight: 500;
      font-size: 33px;
      line-height: 40px;
      color: $turquoise;

      &.negative-balance {
        color: $red;
      }
    }

    .dashboard-balance__action {
      margin-left: auto;

      .see-more {
        width: 40px;
        height: 40px;
      }
    }
  }
}
