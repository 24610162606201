.account {
  width: 100%;

  .tabs {
    overflow: visible;

    .ant-tabs-nav-wrap {
      position: absolute;
      right: 0;
      top: -52px;
    }
  }
}

.account-table {
  .icon.download {
    width: 10px;
    height: 14px;
    margin-left: 4px;
  }
}
