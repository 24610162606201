.profile {
  .profile-box-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 28px;
    margin-bottom: 24px;
  }

  .profile-box-header__edit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    padding: 0;
    border: none;
    color: $pink;
    background-color: transparent;
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }

  .profile-box__inner {
    display: flex;
    align-items: center;

    + .profile-box__inner {
      margin-top: 8px;
    }
  }

  .profile-box__link {
    margin-top: 8px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }

  .profile-box__text {
    margin-bottom: 16px;
  }

  .profile-box__value {
    margin-left: 8px;
  }

  .profile-header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 116px;
    margin: 0 20px 60px 20px;
    padding: 0 24px;
    background-color: $white;
    box-shadow: 0 3px 22px rgba(48, 22, 81, 0.14);
    border-radius: 8px;
  }

  .profile-header__avatar {
    width: 120px;
    height: auto;
    margin-top: -82px;
  }

  .profile-header__title {
    margin-left: 34px;
    padding: 40px 0;
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    color: $blueDark;
  }

  .profile__container {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 405px);

    @media (max-width: 1909px) {
      flex-direction: column;
      width: 60%;
    }
  }

  .boxes {
    flex-wrap: wrap;
    margin: 0 -20px;
  }

  .box {
    margin-left: 20px;
    margin-right: 20px;
  }

  .boxes__col {
    + .boxes__col {
      margin: 0;
    }

    &.left {
      flex-basis: 700px;

      @media (max-width: 1909px) {
        flex-basis: 75%;
      }
    }

    &.center {
      flex-grow: 1;

      @media (min-width: 1480px) {
        min-width: 640px;
      }
    }

    &.right {
      flex-grow: 1;

      @media (max-width: 1909px) {
        margin-top: 40px;
      }

      @media (min-width: 1910px) {
        flex-basis: 492px;
        flex-grow: 0;
      }
    }
  }
  .profile-box {
    .form {
      max-width: 312px;
      .btn {
        margin-top: 24px;
      }

      .text-green-normal-f16-l28 {
        margin-top: 16px;
      }
    }
  }
}

.profile-modal {
  .btn {
    margin-top: 24px;
  }

  .field-grey-straight-border {
    margin-top: -24px;
  }
}
