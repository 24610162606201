.sidebar {
  position: relative;
  z-index: 2;
  min-width: 258px;
  background-color: $white;
  box-shadow: 0 10px 30px rgba(96, 96, 96, 0.23);
  transition: 0.3s all ease;

  &.hidden {
    margin-left: -258px;
    box-shadow: 0 0 0 transparent;

    .sidebar__toggle {
      right: -58px;
      transform: rotate(-180deg);
    }
  }

  &:after {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    width: 100%;
    height: 650px;
    background-image: url("../../images/sidebar-bg.svg");
  }

  &__toggle {
    position: absolute;
    z-index: 2;
    content: '';
    top: 22px;
    right: -10px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: none;
    border-radius: 50%;
    background-color: $turquoise;
    color: $white;
    cursor: pointer;
    transition: right 0.3s, background-color 0.3s;

    &:hover,
    &.active {
      background-color: $purpleDark;
    }

    &.active:hover {
      background-color: $turquoise;
    }
  }

  &__wrapper {
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center;
    height: 76px;
    padding: 0 32px;
    border-bottom: 1px solid $greyLight;
  }

  &__link {
    display: inline-flex;
  }

  &__logo {
    max-width: 178px;
  }

  &-nav {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 108px);
    margin: 16px 0;
    padding: 12px;
    overflow: auto;

    &__link {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 8px 24px;
      border: none;
      text-align: left;
      background-color: transparent;
      text-decoration: none;
      border-radius: 4px;
      color: $greyDark;
      font-size: 16px;
      line-height: 28px;
      cursor: pointer;
      transition: color 0.3s, background-color 0.3s, box-shadow 0.3s;

      &.disabled {
        opacity: 0.3;
        cursor: not-allowed;

        &:hover {
          background-color: transparent;
          color: $greyDark;
          box-shadow: none;
        }
      }

      &:hover,
      &.active {
        color: $white;
        background-color: $turquoise;
        box-shadow: 0 2px 4px rgba(138, 146, 166, 0.3);
      }

      + .sidebar-nav__link {
        margin-top: 2px;
      }

      .icon {
        margin-right: 16px;
      }
    }
  }
}
