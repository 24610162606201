.signin-page,
.recovery-password-page,
.restore-password-page {
  .page__content {
    height: 100%;
    padding: 0;
  }
}

.signin {
  display: flex;
  height: 100%;

  &__col {
    width: 50%;

    &_form {
      display: flex;
      flex-direction: column;
      padding: 80px;
      overflow: auto;

      &:after {
        position: absolute;
        content: '';
        left: 0;
        bottom: 0;
        width: 500px;
        height: 450px;
        background-image: url("../../images/signin-bg.svg");
        background-size: cover;
        background-position: center;
      }
    }

    &_banner {
      background-image: url("../../images/login-bg.jpg");
      background-size: cover;
    }
  }

  &__inner {
    position: relative;
    z-index: 2;
    max-width: 488px;
    margin: auto;
    padding: 60px 0;
    text-align: center;

    .text-grey-dark-normal-f16-l28 {
      margin-top: 8px;
    }
  }

  &__link {
    display: inline-flex;
  }

  &-form {
    padding: 16px 16px 24px 16px;

    .btn {
      min-width: 188px;
      margin: 40px auto 0 auto;
    }

    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;
    }
  }
}

.recovery-password-form {
  margin-top: 16px;
  .btn {
    margin-top: 40px;
  }
  .text-green-normal-f16-l28 {
    margin-top: 16px;
  }
}
