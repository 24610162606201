.form {
  .field + .field {
    margin-top: 16px;
  }
}

.field-grey-straight-border {
  .field {
    margin-top: 24px;
    width: 100%;
  }

  .field__input {
    border-color: $greyLight;
    border-radius: 4px;
    height: 44px;

    &.disabled {
      opacity: 0.8;
      cursor: not-allowed;
      color: $grey;

      &:hover {
        border-color: $greyLight;
      }
    }
  }
}

.field {
  display: flex;
  text-align: left;

  &_col {
    flex-direction: column;

    .field__label {
      margin-bottom: 8px;
    }
  }

  &_row {
    align-items: center;

    .field__label {
      margin-left: 8px;
    }
  }

  &__label {
    font-size: 16px;
    line-height: 28px;
    color: $greyDark;
    cursor: default;
  }

  &__input {
    height: 40px;
    padding: 0 16px;
    font-size: 16px;
    color: $greyDark;
    background-color: $white;
    border: 1px solid $grey;
    border-radius: 22px;
    outline: none;
    transition: border-color 0.3s;

    .ant-input-group-addon {
      background-color: $white;
      border: 1px solid red;
    }

    &.ant-input:focus,
    &.ant-input-focused {
      box-shadow: none;
    }

    &:focus,
    &:hover {
      border-color: $pink;
    }
  }

  &__checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    padding: 2px;
    background-color: $white;
    border: 1px solid $greyDark;
    color: $pink;
    box-shadow: 0 4px 8px rgba(0, 16, 41, 0.1);
    border-radius: 2px;
    cursor: pointer;
    transition: border 0.3s;

    &:hover,
    &.checked {
      border: 1px solid $pink;
    }
  }
  .checkbox-list {
    display: flex;

    .field {
      width: max-content;
      margin-top: 8px;

      &:not(:last-child) {
        margin-right: 36px;
      }
    }

    .field__checkbox {
      box-shadow: none;

      + .field__label {
        margin-bottom: 0;
      }
    }
  }
  .special-label {
    display: none;
  }

  .field__error {
    margin-top: 8px;
    font-size: 12px;
    line-height: 16px;
    color: $red;
  }
}

.field__select {
  &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    &:hover {
      border-color: $pink;
    }
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input),
    .ant-select-selector {
    border-color: $pink;
    box-shadow: none;
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    min-height: 44px;
    padding: 8px 16px;
    border: 1px solid $greyLight;
    font-size: 16px;
    color: $greyDark;
    outline: none;
    border-radius: 4px;

    .ant-select-selection-search {
      display: flex;
      align-items: center;
      height: 100%;
    }

    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      display: flex;
      align-items: center;
      line-height: 42px;
    }

    .ant-select-selection-overflow,
    .ant-select-selection-overflow-item,
    .ant-select-selection-item {
      line-height: initial;
    }

    .ant-select-selection-placeholder {
      color: $greyDark;
    }

    .ant-select-selection-item-remove {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .ant-select-arrow {
    color: $purpleDark;
  }

  &.ant-select-open {
    transition: transform 0.3s;

    .ant-select-arrow {
      transform: rotate(180deg);
      color: $pink;
    }
  }
}

.upload-file {
  position: relative;
  display: flex;
}
.ant-upload-list {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 290px;
  height: 100%;

  .ant-upload-list-text-container {
    height: 100%;
  }

  .ant-upload-list-item {
    height: 100%;
    margin-top: 0;
    color: inherit;
  }
  .ant-upload-list-item-name,
  .ant-upload-list-item-card-actions .anticon{
    color: inherit;
  }

  .ant-upload-text-icon {
    display: none;
  }

  .ant-upload-list-text-container {
    color: #2E1A47;

    .ant-btn-text {
      color: #2E1A47;
    }
  }
}
.upload-file__wrapper {
  display: flex;
  flex-direction: column;

  .upload-file-input {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    background: $white;
  }

  .upload-file__label {
    display: flex;
    align-items: center;
    justify-content: left;
    flex-grow: 1;
    padding: 0 16px;
    height: 100%;
    border: 1px solid #f5f6fa;
    border-radius: 0 4px 4px 0;
    border-left: none;
    background: #E9ECEF;
    color: $greyDark;

    &.success {
      background: $white;
    }
  }
  .upload-file__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 16px;
    border: 1px solid #EEEEEE;
    border-radius: 4px 0 0 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: $greyDark;
  }
}

input::placeholder {
  color: $greyDark;
}

input[type='file'] {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


// inputs

.ant-form-item {
  flex-direction: column;
  justify-content: flex-start;

  &:hover {
    .ant-input,
    .ant-input-group-addon {
      border-color: #DB3EB1 !important;
    }
  }

  .ant-form-item-label {
    text-align: left;

    > label {
      height: auto;
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 18px;
      color: $greyDark;
      cursor: default;
    }
  }

  .ant-input-number,
  .ant-input {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 38px;
    padding: 8px 16px;
    border: 1px solid $greyLight;
    font-size: 16px;
    line-height: 28px;
    color: $greyDark;
    outline: none;
    border-radius: 4px;

    &:hover {
      border-color: $pink;
      box-shadow: none;
    }

    &::placeholder {
      color: $grey;
    }

    + .ant-input-group-addon {
      padding: 0 22px;
      border-color: $greyLight;
      background-color: transparent;
      font-size: 19px;
      line-height: 175%;
      color: transparentize($grey, 0.5);
    }
  }

  .ant-input-number-input {
    padding: 0;
    font-size: 16px;
    line-height: 28px;
    color: $greyDark;
  }

  &.ant-input:focus, .ant-input-focused {
    border-color: $pink;
    box-shadow: none;
  }
}

// select

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  &:hover {
    border-color: $pink;
  }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input),
.ant-select-selector {
  border-color: $pink;
  box-shadow: none;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  color: rgba(0, 0, 0, 0.25);
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  min-height: 46px;
  padding: 8px 16px;
  border: 1px solid $greyLight;
  font-size: 16px;
  color: $greyDark;
  outline: none;
  border-radius: 4px;

  .ant-select-selection-search {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    display: flex;
    align-items: center;
    line-height: 42px;
  }

  .ant-select-selection-overflow,
  .ant-select-selection-overflow-item,
  .ant-select-selection-item {
    line-height: initial;
  }

  .ant-select-selection-placeholder {
    color: $greyDark;
  }

  .ant-select-selection-item-remove {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.ant-select-arrow {
  color: $purpleDark;
}

.ant-select-open {
  transition: transform 0.3s;

  .ant-select-arrow {
    transform: rotate(180deg);
    color: $pink;
  }
}

// date picker

.ant-picker {
  width: 100%;
  min-height: 44px;
  padding: 8px 16px;
  border: 1px solid $greyLight;
  outline: none;
  border-radius: 4px;

  &:hover {
    border-color: $pink;
  }

  &.ant-picker-focused {
    border-color: $pink;
    box-shadow: none;
  }

  .ant-picker-input > input {
    font-size: 16px;
    line-height: 28px;
    color: $greyDark;
  }
}

.ant-input-group {
  .ant-input {
    border-right: none;
  }
}

.ant-input:focus, .ant-input-focused {
  box-shadow: none;
}

.ant-input[disabled] {
  background-color: transparent;
}

textarea {
  resize: none;
}

.ant-form-item__checkbox {
  .field__label {
    line-height: 22px;

    a {
      &:hover {
        color: $turquoise;
        text-decoration: underline;
      }
    }
  }

  &.ant-form-item-has-error {
    .ant-checkbox-inner {
      border: 1px solid #ff4d4f;
    }
  }
}
