.label-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 45px;
  height: 45px;
  background-color: $blueDark;
  color: $white;
  border-radius: 4px;

  &.purple-bg {
    background-color: $purple;
  }

  &.purpleDark-bg {
    background-color: $purpleDark;
  }

  &.turquoise-bg {
    background-color: $turquoise;
  }

  &.pink-bg {
    background-color: $pink;
  }

  &.orange-bg {
    background-color: $orange;
  }

  &.green-bg {
    background-color: $green;
  }

  &.yellow-bg {
    background-color: $yellow;
  }

  &.red-bg {
    background-color: $red;
  }

  &.blueDark-bg {
    background-color: $blueDark;
  }

  &.grey-bg {
    background-color: $grey;
  }

  &.greyDark-bg {
    background-color: $greyDark;
  }

  &.greyLight-bg {
    background-color: $greyLight;
  }

  &.purple-color {
    color: $purple;
  }

  &.purpleDark-color {
    color: $purpleDark;
  }

  &.turquoise-color {
    color: $turquoise;
  }

  &.pink-color {
    color: $pink;
  }

  &.orange-color {
    color: $orange;
  }

  &.green-color {
    color: $green;
  }

  &.yellow-color {
    color: $yellow;
  }

  &.red-color {
    color: $red;
  }

  &.blueDark-color {
    color: $blueDark;
  }

  &.grey-color {
    color: $grey;
  }

  &.greyDark-color {
    color: $greyDark;
  }

  &.greyLight-color {
    color: $greyLight;
  }
}
