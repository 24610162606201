@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.main-loader {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  -webkit-animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  -o-animation: spin 2s linear infinite;
  -ms-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.inside-loader {
  width: 100%;
  height: 50%;
  position: absolute;
  margin-top: 50%;
  background: linear-gradient(90deg, rgb(220, 220, 220), rgb(150, 150, 150));
}
.inside-loader:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  margin-top: -50%;
  background: linear-gradient(90deg, rgb(50, 50, 50), rgb(160, 160, 160));
}
.inside-loader:after {
  content: '';
  width: 80%;
  height: 160%;
  position: absolute;
  margin-top: -40%;
  margin-left: 10%;
  background: white;
  border-radius: 50%;
}
