.stock {
  .boxes__col.left {
    min-width: 496px;
    max-width: 496px;
  }

  .boxes__col.right {
    flex-grow: 1;
  }

  textarea.ant-input {
    min-height: 342px;
  }

  .upload .ant-upload {
    width: 100%;
  }
}
