.default-table {
  max-width: calc(100% + 48px);
  margin: 0 -24px -24px -24px;
  font-family: 'Inter', sans-serif;

  .default-table__cell-row {
    display: flex;
    align-items: center;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    content: none;
  }

  .ant-table-cell {
    &:first-child {
      padding-left: 36px;
    }

    &:last-child {
      padding-right: 36px !important;
    }

    &.center {
      text-align: center;

      .btn {
        margin: 0 auto;
      }
    }
  }

  .ant-table-thead {
    background-color: $greyLight;
    height: 44px;

    .ant-table-cell {
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      color: $greyDark;
      border-bottom: 0;
      padding: 0 0 0 12px;

      &:first-child {
        padding-left: 36px;
      }

      &:last-child {
        padding-right: 36px;
      }
    }
  }

  &.request-table {
    .ant-table-tbody {
      .ant-table-row {
        height: 66px;
        cursor: pointer;

        &:nth-child(even) {
          background-color: #fbfcfc;
        }

        .ant-table-cell {
          border-bottom: 0;
          padding: 0 0 0 12px;

          &:first-child {
            padding-left: 36px;
          }
          &.id {
            span {
              font-weight: normal;
              font-size: 13px;
              line-height: 17px;
              color: $greyDark;
              position: relative;
            }
          }
          &.category {
            font-weight: normal;
            font-size: 16px;
            line-height: 28px;
            color: $turquoise;
          }
          &.type {
            font-weight: normal;
            font-size: 16px;
            line-height: 28px;
            color: #232d42;
          }

          &.more {
            .icon {
              width: 40px;
              height: 40px;
            }
          }
        }
      }
    }

    .ant-pagination {
      display: flex;
      justify-content: center;
      margin: 0;
      position: absolute;
      bottom: -(calc(24px + 38px + 36px));
      width: 100%;
    }
  }

  .ant-pagination-item {
    margin-right: 0;
    height: 38px;
    line-height: 38px;
    border-radius: 0;
    border-top: 1px solid #adb5bd;
    border-bottom: 1px solid #adb5bd;
    border-right: 0;
    border-left: 0;
    &:not(.ant-pagination-item-1) {
      border-left: 1px solid #adb5bd;
    }
    &.ant-pagination-item-active {
      a {
        color: #db3eb1;
      }
    }
  }
  .ant-pagination-prev {
    margin-right: 0;
    min-width: 60px;
    height: 38px;
    .ant-pagination-item-link {
      border-radius: 8px 0 0 8px;
    }
  }
  .ant-pagination-next {
    margin-right: 0;
    min-width: 60px;
    height: 38px;
    .ant-pagination-item-link {
      border-radius: 0 8px 8px 0;
    }
  }

  .ant-pagination-item-link {
    border: 1px solid #adb5bd;
    height: 38px;
    line-height: 38px;
  }

  .f13-grey-dark {
    font-size: 13px;
    line-height: 130%;
    color: $greyDark;
  }

  .f13-grey {
    font-size: 13px;
    line-height: 130%;
    color: $grey;
  }

  .f16-grey-dark {
    font-size: 16px;
    line-height: 175%;
    color: $greyDark;
  }

  .expandable-text {
    max-width: 550px;
    width: 100%;
    overflow: hidden;
    min-width: 350px;
    transition: all 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    cursor: pointer;
    display: -webkit-box;
    line-height: 1.3em;
    -webkit-box-orient: vertical;

    &--expanded {
      -webkit-line-clamp: unset;
      max-height: 1000px;
    }

    &--collapsed {
      -webkit-line-clamp: 2;
      max-height: calc(1.3em * 2);
    }
  }

  .f16-black {
    font-size: 16px;
    line-height: 175%;
    color: #04040b;
  }
}

.request-label {
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  &.done {
    color: #1aa053;
  }
  &.cancel {
    color: $red;
  }
  &.process {
    color: $yellow;
  }
}
