$purple: #9A18FF;
$purpleDark: #2E1A47;
$turquoise: #2CD5C4;
$pink: #DB3EB1;
$orange: #F16A1B;
$green: #1AA053;
$successDark: #409900;
$yellow: #FFD329;
$red: #C03221;
$blueDark: #232D42;
$grey: #ADB5BD;
$greyDark: #8A92A6;
$greyLight: #F5F6FA;
$white: #fff;
