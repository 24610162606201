.marketing-page {
  .boxes {
    flex-direction: column;
  }
  .box {
    width: 100%;
  }

  .marketing-header {
    div + div {
      margin-top: 24px;
    }
  }

  .upload-files-box {
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
    .ant-form-item {
      flex-shrink: 0;
      width: calc(50% - 12px);
    }
  }
  .marketing-fields-box {
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;

    .field-shop {
      max-width: 50%;
    }
    .field-floor {
      max-width: 66px;
    }
    .field-phone {
      max-width: 206px;
      .special-label {
        display: none;
      }
    }
    .field-site {
      max-width: 286px;
    }
    .field-work-time {
      max-width: 200px;
    }
    .field-work-day {
      max-width: max-content;
    }
    .field-short-shop-description {
      width: 100%;
    }
    .field-btn {
      display: flex;
      width: 100%;
      .ant-form-item-control-input-content {
        display: flex;
        justify-content: flex-end;
      }
    }
    .btn {
      width: 100%;
      max-width: 280px;
      height: 44px;
    }
  }
}
