.commodity {
  flex-direction: column;

  .commodity__content {
    max-width: 1052px;
    margin-top: 32px;

    &.disabled {
      .commodity__title,
      .commodity__text {
        color: #C03221;
      }

      .ant-form-item {
        flex-direction: column;
        justify-content: flex-start;

        &:hover {
          .ant-input,
          .ant-input-group-addon {
            border-color: #F5F6FA !important;
          }
        }
      }
    }
  }

  .commodity__header {
    display: flex;
    align-items: center;
    min-height: 92px;
  }

  .commodity__text {
    max-width: 318px;
    margin-top: 8px;
  }

  .commodity-form {
    margin-top: 24px;
    position: relative;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }

    .btn {
      height: 44px;
    }
  }
  .commodity-form__footer-title {
    display: flex;
    justify-content: center;

    &.success {
      color: $successDark;;
    }
    &.error {
      color: $red;
    }
  }

  .commodity-form__row {
    display: flex;
  }

  .commodity-form__col {
    min-width: 430px;
    max-width: 430px;

    + .commodity-form__col {
      margin-left: 24px;
    }

    .btn {
      width: 100%;
    }

    .ant-upload {
      min-width: 100%;
    }
  }

  .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-tabs-nav-wrap {
    justify-content: flex-end;
  }

  .tabs {
    overflow: visible;

    .ant-tabs-nav-wrap {
      position: absolute;
      top: -69px;
      right: 24px;
    }
  }
}

.commodity-form-max-wrapper {
  max-width: 516px;
}

.commodity-form__upload {
  display: flex;
  align-items: center;
  height: 48px;
  padding: 8px 16px;
  border: 1px solid #eee;
  border-radius: 4px;
}
