@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');
@import 'common/colors';
@import 'common/basic';
@import 'common/loader';
@import 'common/icons';
@import 'common/typography';

@import 'components/table';
@import 'components/button';
@import 'components/tabs';
@import 'components/fields';
@import 'components/sidebar';
@import 'components/header';
@import 'components/footer';
@import 'components/banner';
@import 'components/label-icon';

@import 'pages/signin';
@import 'pages/support';
@import 'pages/dashboard';
@import 'pages/marketing';
@import 'pages/profile';
@import 'pages/accounts';
@import 'pages/commodity';
@import 'pages/stock';
