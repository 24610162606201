.ant-tabs-tab {
  padding: 8px 16px;
  border: 1px solid transparent;
  border-radius: 50px;

  + .ant-tabs-tab {
    margin-left: 16px;
  }

  &.ant-tabs-tab-active {
    border-color: $turquoise;

    .ant-tabs-tab-btn {
      color: $purpleDark;
      text-shadow: none;
    }
  }
}

.ant-tabs-tab-btn {
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  color: $purpleDark;
}

.ant-tabs-nav:before {
  content: none !important;
}

.ant-tabs-ink-bar {
  display: none;
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin-bottom: 24px;
}
