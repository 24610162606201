* {
  box-sizing: border-box;
}

body {
  min-width: 1280px;
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-variant: normal;
}

.page {
  position: relative;
  display: flex;
  height: 100vh;
  overflow: hidden;
  background-color: $white;

  &__container {
    flex-grow: 1;
    height: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 76px);
    padding: 0 40px;
    overflow: auto;

    > .loading {
      position: fixed;
      z-index: 666;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $white;
    }
  }
}

.link {
  font-size: 16px;
  line-height: 28px;
  color: $turquoise;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.boxes {
  position: relative;
  z-index: 2;
  display: flex;
  margin-top: -56px;
  margin-bottom: 58px;

  .boxes__col + .boxes__col {
    margin-left: 40px;
  }
}

.box {
  padding: 24px;
  background-color: $white;
  box-shadow: 0 3px 22px rgba(48, 22, 81, 0.14);
  border-radius: 8px;

  + form,
  + .box {
    margin-top: 40px;
  }
}

.box__title-inner {
  margin-right: 500px;
}

.flex-center {
  display: flex;
  align-items: center;
}

.success-modal {
  min-height: 500px;
  text-align: center;

  &__wrapper {
    max-width: 330px;
    margin: 0 auto;
  }

  .ant-btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: end;
    button {
      margin: 0 10px; /* По 10px с каждой стороны */
    }
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 160px;

    div + div {
      margin-top: 10px;
    }
  }
}

.ant-upload {
  color: #8a92a6;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: none;
  border-color: #db3eb1;
}
