.footer {
  display: flex;
  align-items: center;
  min-height: 48px;
  margin-top: auto;
  padding-top: 22px;
  box-sizing: content-box;

  .footer__pdf {
    margin-right: 24px;
  }

  &__link {
    margin-left: auto;
  }
}
