.banner {
  position: relative;
  width: calc(100% + 80px);
  min-height: 276px;
  margin: -76px -40px 0 -40px;
  background-image: radial-gradient(86.89% 758.4% at 16.12% 63.04%, #351858 0%, #140527 100%);
  border-radius: 0 0 16px 16px;
  overflow: hidden;

  &:after {
    position: absolute;
    content: '';
    right: 0;
    bottom: 0;
    width: 612px;
    height: 200px;
    background-image: url("../../images/banner-bg.png");
    background-size: cover;
    background-position: bottom;
  }

  &__inner {
    position: relative;
    z-index: 2;
    padding: 76px 40px 0 40px;
  }

  .text-white-bold-f40-l48 {
    margin-top: 18px;
  }

  .text-white-medium-f22-l30 {
    margin-top: 8px;
  }
}
