.btn {
  padding: 8px 24px;
  font-size: 16px;
  line-height: 28px;
  color: $white;
  border: none;
  border-radius: 22px;
  cursor: pointer;
  transition: box-shadow 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    box-shadow: 0 0 0 transparent;
  }

  &.block {
    width: 100%;
  }

  svg {
    margin-left: 16px;
  }

  &.orange-bg {
    background-color: $orange;
    box-shadow: 0 2px 4px rgba(241, 106, 27, 0.25);
  }

  &.purple-bg {
    background-color: $purple;
    box-shadow: 0 2px 4px rgba(154, 24, 255, 0.25);
  }

  &.pink-bg {
    background-color: $pink;
    box-shadow: 0 8px 16px rgba(219, 62, 177, 0.25);
  }

  &.turquoise-bg {
    background-color: $turquoise;
    box-shadow: 0 2px 4px rgba(69, 233, 216, 0.25);
  }

  &.yellow-bg {
    background-color: $yellow;
    box-shadow: 0 2px 4px rgba(255, 211, 41, 0.25);
  }

  &.disabled {
    background-color: #6C757D;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
    color: #fff;
    cursor: not-allowed;
  }
}

.upload {
  .upload__choose {
    cursor: pointer;
    border: 1px solid #F5F6FA;
    background-color: $white;
    padding: 8px 16px;
    font-size: 16px;
    line-height: 175%;
    color: #8A92A6;
    transition: border-color 0.3s;

    &:hover {
      border-color: $pink;
    }
  }

  .ant-upload-disabled .upload__choose {
    cursor: not-allowed;

    &:hover {
      border-color: #F5F6FA;
    }
  }
}

.ant-btn:hover, .ant-btn:focus {
  color: $white;
}
